<template>
  <teleport to="head">
    <title>О компании</title>
  </teleport>
  <main class="about-company main page__view">
    <h2 class="header-text">
      КОМПАНИЯ «Ореховское» — МЕЧТА ОБРЕТАЕТ ФОРМУ!
    </h2>
    <p>
      В нашем Производстве на профессиональном уровне, с помощью немецких
      технологий, разрабатывается тротуарная плитка, брусчатка, фасадная плитка,
      дорожные - садовые бордюры, водосток, поребрик. То как развивалась
      компания можете увидеть в разделе фотогалерея. Там есть фотографии, на
      которых показана кампания в начале своей деятельности и сейчас, когда у
      нас все автоматизировано.
    </p>

    <img
      class="about-company__image"
      src="/images/o-kompanii.jpg"
      alt="О компании"
    />
    <p>
      Первая и самая главная — это Красота. Тротуарная плитка уложенная
      компанией «Ореховское» улучшит внешний вид территории в десятки раз.
    </p>

    <ul class="our-advantages">
      <li class="our-advantages__item">
        <fontAwesomeIcon class="check-icon" icon="check" />
        Вторая, это конечно же экономическая сторона. На данный момент времени
        стоимость материала меньше стоимости бетона. По этому ее выгоднее класть
        не желе бетон.
      </li>
      <li class="our-advantages__item">
        <fontAwesomeIcon class="check-icon" icon="check" />
        Третья, это тротуарная плитка, как материал неприхотлива и это тоже
        упрощает работу с этим материалом.
      </li>
      <li class="our-advantages__item">
        <fontAwesomeIcon class="check-icon" icon="check" />
        Четвертая – ее легко комбинировать и заменять.
      </li>
      <li class="our-advantages__item">
        <fontAwesomeIcon class="check-icon" icon="check" />
        Пятая – огромное разнообразие форм и рисунков.
      </li>
      <li class="our-advantages__item">
        <fontAwesomeIcon class="check-icon" icon="check" />
        Шестая – быстрота монтажа компанией «Ореховское».
      </li>
      <li class="our-advantages__item">
        <fontAwesomeIcon class="check-icon" icon="check" />
        Седьмая — тротуарная плитка настолько прочная, что легко выдерживает как
        легковые машины так и грузовые, тяжеловесные. Важно лишь подобрать класс
        прочности плитки соответственно целям эксплуатации.
      </li>
      <li class="our-advantages__item">
        <fontAwesomeIcon class="check-icon" icon="check" />
        Восьмая - Богатый опыт наших рабочих позволяет получить качественный
        товар, а с помощью автоматизации снижается цена, что позволяет нам чаще
        делать скидки на тротуарную плитку, брусчатку и на весь товар от 5-10%.
      </li>
    </ul>
  </main>
</template>

<style scoped>
.about-company {
  display: grid;
  grid-row-gap: 15px;
}

.about-company__image {
  height: 400px;
  width: 100%;
}

.our-advantages {
  list-style: none;
  display: grid;
  grid-row-gap: 10px;
}

.check-icon {
  color: var(--primary-color);
  width: 1em;
  margin-right: 3px;
}
</style>
